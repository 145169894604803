<template>
    <div> {{version.name}} [{{version.libelle}}]</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: {type: Object, require: true}
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            versions: 'host/versions'
        }),
        version(){
            return this.versions.find(item => this.object.version === item.uid)
        }
    }
}
</script>